import { ThunkDispatch } from 'redux-thunk';
import { FreshdeskService } from '../../services/freshdesk.service';
import { ActionTypes } from '../constants/action.types';
import { RootState } from '../types';
import { IFreshdeskAction } from './types';

const freshdeskService = new FreshdeskService();

export const freshdeskSupportAction = Object.assign(
  (name: string, email: string, message: string) =>
    async (dispatch: ThunkDispatch<RootState, void, IFreshdeskAction>) => {
      dispatch(freshdeskSupportAction.start());
      try {
        await freshdeskService.requestSupport(name, email, message);
        dispatch(freshdeskSupportAction.success());
      } catch (error) {
        const errorMessage =
          (error as Error)?.message ??
          (error as string) ??
          'Something went wrong';
        dispatch(freshdeskSupportAction.fail(errorMessage));
      }
    },
  {
    start: (): IFreshdeskAction => ({ type: ActionTypes.GET_FRESHDESK_START }),
    success: (): IFreshdeskAction => ({
      type: ActionTypes.GET_FRESHDESK_SUCCESS,
    }),
    fail: (error: string): IFreshdeskAction => ({
      type: ActionTypes.GET_FRESHDESK_FAIL,
      error,
    }),
  }
);
