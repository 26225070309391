import { useState } from 'react';
import classes from './VerticalCarousel.module.css';

interface DataContent {
  title: string;
  img: string;
  description: string;
}

interface VerticalCarouselProps {
  data: DataContent[];
}

const VerticalCarousel = ({ data }: VerticalCarouselProps) => {
  const [selected, setSelected] = useState(0);

  const toggle = (index: number) => {
    return setSelected(index);
  };

  return (
    <>
      <div className={classes.verticalCarousel}>
        <div className={classes.leftCol}>
          <div>
            <img src={data[selected].img} alt={data[selected].description} />

            <div className={classes.description}>
              {data[selected].description}
            </div>
          </div>
        </div>
        <div className={classes.rightCol}>
          <div data-cy="verticalSlider">
            {data.map((item, index) => (
              <div key={item.title}>
                <div className={classes.titleBox}>
                  <button
                    type="button"
                    className={
                      selected === index
                        ? classes.verticalCarouselButtonActive
                        : classes.verticalCarouselButton
                    }
                    onClick={() => toggle(index)}
                    onKeyUp={() => toggle(index)}
                  >
                    <div className={classes.title}>{item.title}</div>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={classes.verticalCarouselMobile}>
        {data.map((item) => (
          <div key={item.title} className={classes.verticalCarouselBox}>
            <div className={classes.title}>{item.title}</div>
            <img src={item.img} alt={item.description} />
            <div className={classes.description}>{item.description}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export default VerticalCarousel;
