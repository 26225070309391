import FamilyRunning from '../../assets/FamilyRunning.png';
import GrandmaSelfie from '../../assets/GrandmaSelfie.png';
import SocialGroup from '../../assets/SocialGroup.png';

export const dutyData = [
  {
    title: 'Curate your content',
    content: `Quotient allows you to enjoy your information without the distraction of unrequested ads or aggressive marketing campaigns. 
    Keep yourself free of irrelevant viral content and fill your feed with the updates YOU decide to see.`,
  },
  {
    title: 'Keep your community safe',
    content: `The only way someone can access your Quotient network is through an invitation.
    You may have the comfort of being able to share your personal experiences without the fear of them filtering into the unknown.`,
  },
  {
    title: 'Express yourself with freedom',
    content: `You can create in Quotient a safe place where you can share your ideas without the fear of being banned or silenced.
    You can create your community and share your beliefs, artwork, experiences, and more. The sky is the limit!`,
  },
  {
    title: 'Organize your Experience',
    content: `With Quotient's multiple galleries, you can have different spaces to share with your family, friends, and work colleagues, and even one to share your hobbies!
    You don't have to create multiple accounts to separate your interests.`,
  },
];

export const changeData = [
  {
    title: 'Quotient is a private social network.',
    img: SocialGroup,
    description:
      'It is a platform that helps you create your own social networks.',
  },
  {
    title: 'Quotient does not have access to your data.',
    img: GrandmaSelfie,
    description:
      'It is a platform that helps you create your own social networks.',
  },
  {
    title: 'In Quotient, you control your audience.',
    img: FamilyRunning,
    description:
      'It is a platform that helps you create your own social networks.',
  },
];

export const faqData = [
  {
    faqTitle: 'What is Quotient?',
    faqText:
      'Quotient is a privacy-focused decentralized platform that empowers you to create your own social networks easily.',
    faqActive: true,
  },
  {
    faqTitle: 'Who is Quotient for?',
    faqText:
      'Quotient was developed with everyone in mind… particularly those concerned about their internet privacy. Anyone can use Quotient, and you can rest assured that you will never be censored or prohibited from using the app. That is against our values.',
    faqActive: false,
  },
  {
    faqTitle: 'Is Quotient Available for both iOS and Android?',
    faqText:
      'Yes, it is available in both platforms as Quotient QT, short for Quotient Terminal. You can download the app through the App Store in iOS, or in the Play Store in Android.',
    faqActive: false,
  },
  {
    faqTitle:
      'Why should I use Quotient instead of other social media apps on the market?',
    faqText:
      'Quotient takes pride in providing a social media app like the ones you are used to using, in a secure and private app. We will never offer advertising, and your data will never be sold. Also, Quotient runs on a decentralized network meaning your networks are visible only to those who you have invited in.',
    faqActive: false,
  },
  {
    faqTitle: 'What is a Q?',
    faqText:
      'A Q is a server where your social networks “live”. Everything you share on your networks is stored in your own Q, and only you have access to it. Not even we can see the information on your Q, everything is end-to-end encrypted for your privacy and security.',
    faqActive: false,
  },
  {
    faqTitle: 'What are the costs of owning a Q?',
    faqText:
      'That depends on what option you go for. A virtual Q (vQ) will cost you $14.99/month, while a hardware Q (hQ) will have a one-time cost of $399. Both options include 5 networks, or as we call them, Galeries. Friends you invite into your Galleries enter for free.',
    faqActive: false,
  },
  {
    faqTitle: 'What is a Gallery?',
    faqText:
      'A Gallery is its own social network. As a Q owner you send invites to people so they can join your gallery, and you can assign them special roles with special privileges. 5 Galleries are included with your purchase of a vQ or an hQ, but more can be purchased if necessary.',
    faqActive: false,
  },
  {
    faqTitle: 'What are user Roles?',
    faqText:
      'In the app, you will find the following terminology that defines different user roles. Owner: the account holder who claimed the vQ or setup an hQ for the first time. Host: a Gallery administrator with full permissions to change its settings, invite users and post. Co-Host: a Gallery member that is able to post. Guest: A Gallery guest that is only able to view posts.',
    faqActive: false,
  },
  {
    faqTitle: 'Should I get a vQ or an hQ?',
    faqText:
      'Both Q types are very similar. A vQ (Virtual Q) runs on our servers for your convenience, yet we don’t have access to your information to ensure your privacy. An hQ is a tiny server you can have in your home, ensuring you have full control over your Galleries and information at all times.',
    faqActive: false,
  },
  {
    faqTitle:
      'Can I reset an hQ if I want to start over or give it to someone else?',
    faqText:
      'Yes, the app provides you the functionality to do a full reset, eliminating everything in the hQ for your privacy.',
    faqActive: false,
  },
  {
    faqTitle: 'Does Quotient have access to / sell my data?',
    faqText:
      'Quotient does not have access to your data and will NEVER sell your information to third parties. Additionally, all your data is encrypted, for your protection. This is part of our core values.',
    faqActive: false,
  },
  {
    faqTitle: 'Does Quotient offer targeted ads?',
    faqText:
      'Quotient does not have advertising and does not plan on offering advertising on its platforms. Having an ad-free experience is part of what makes us unique.',
    faqActive: false,
  },
  {
    faqTitle: 'What happens if someone steals my hQ?',
    faqText:
      "Your privacy and safety are our priority. All data stored in your hQ has been encrypted, so a thief wouldn't be able to extract its data.",
    faqActive: false,
  },
];

export const ourProducts = [
  {
    title: 'How does Quotient Work?',
    content: `Quotient is a private social network on which you can create multiple galleries where you post files and messages with different communities. How do we keep it private? You host your server called a Q. That assures you that no one else but you has access to your information. `,
  },
  {
    title: 'The Q',
    content: `The Q is the server where your galleries live. It stores your files, messages, and members. The content is available to you anywhere in the world as long as it has an internet connection. We offer two kinds of Qs, a virtual Q in the cloud and an physical hardware device.`,
  },
  {
    title: 'How does a Q and QT App work together?',
    content: `Once you have your Q, setting it up is as easy as downloading the QT app and following three little steps. It's quick and easy! After that, you will be able to set up your galleries immediately.`,
  },
  {
    title: 'QT App',
    content: `Is an app that you can use on your smartphone to access your Q, view your Galleries, send messages, upload media and more in a safe and secure way.`,
  },
  {
    title: 'Galleries',
    content: `A Gallery is its own social network where you and your friends can share media, files, messages and more.`,
  },
  {
    title: 'Invitation',
    content: `Invitations allow you to control who can access your galleries. You decide if you want them to be open to the public or if they are selective and private. The choice is yours. `,
  },
  {
    title: 'Messaging',
    content: `Message your friends and family without any additional cost. No one else can access your messages, including Quotient. You control what you send and with who you share it. `,
  },
  {
    title: 'Get Your Q',
    content: 'Be the owner of your own Q, starting with 5 Galleries.',
  },
  {
    title: 'Download the QT App',
    content:
      'Use the app to configure your own Q and Galleries or join existing ones.',
  },
];

export enum StripeMode {
  Payment = 'payment',
  Subscription = 'subscription',
}
