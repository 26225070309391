import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import FormMessage from '../FormMessage/FormMessage';
import { freshdeskSupportAction } from '../../store/freshdesk/freshdesk.actions';
import classes from './ContactForm.module.css';

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [show, setShow] = useState(false);
  const { loadingFreshdesk } = useAppSelector((state) => state.freshdesk);

  const dispatch = useAppDispatch();

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    if (name === '' && email === '' && message === '') {
      event.preventDefault();
    }
    event.preventDefault();
    dispatch(freshdeskSupportAction(name, email, message));
    setShow(true);
  };

  const hideMessageScreen = () => {
    setShow(false);
  };

  return (
    <div className={classes.contactContainer}>
      <h1 className={classes.contactTitle}>How Can We Help?</h1>
      <p className={classes.contactText}>
        Send us a message and someone from our support team will get back to
        you.
      </p>
      <div className={classes.contactFormContainer}>
        <form
          method="post"
          className={classes.contactForm}
          onSubmit={handleSubmit}
        >
          <h3>Full Name</h3>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="e.g. John Doe"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <h3>Email</h3>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="e.g. johndoe@gmail.com"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <h3>Message</h3>
          <textarea
            name="message"
            id="message"
            rows={5}
            placeholder="Type message"
            required
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <div className={classes.submitContainer}>
            <button
              type="submit"
              disabled={!name || !email || !message || loadingFreshdesk}
            >
              Send Message
            </button>
          </div>
        </form>
        {show && <FormMessage hideMessageScreen={hideMessageScreen} />}
      </div>
    </div>
  );
};

export default ContactForm;
