import { NavLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
import QuotientLogo from '../../assets/svg/QuotientLogo';
import classes from './NavBar.module.css';

const NavBar = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  // close when click outside expanded navigation menu
  const navBackground = document.getElementById('navBackground');
  useEffect(() => {
    if (!isNavExpanded && navBackground) {
      navBackground.addEventListener('mousedown', () => {
        setIsNavExpanded(false);
      });
    }
  }, [isNavExpanded]);

  // close on scroll
  document.addEventListener('scroll', () => {
    if (document.getElementsByClassName('container') != null) {
      setIsNavExpanded(false);
    }
  });

  return (
    <div className={classes.container} data-cy="navBar">
      <div
        id="navBackground"
        className={
          isNavExpanded
            ? [classes.navBackground, classes.visible].join(' ')
            : classes.navBackground
        }
      />
      <div
        className={
          isNavExpanded
            ? [classes.navContainer, classes.innerShadowOn].join(' ')
            : [classes.navContainer, classes.innerShadowOff].join(' ')
        }
      >
        <div className={classes.logoContainer}>
          <NavLink to="/">
            <QuotientLogo size={36} color="#2FCF87" /> Quotient
          </NavLink>
        </div>
        <nav className={classes.navigation}>
          <ul className={classes.navigationElementContainer}>
            <li>
              <NavLink
                to="/"
                end
                className={({ isActive }) =>
                  isActive
                    ? classes.navigationElementActive
                    : classes.navigationElement
                }
              >
                <div>Home</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/our-products"
                end
                className={({ isActive }) =>
                  isActive
                    ? classes.navigationElementActive
                    : classes.navigationElement
                }
              >
                <div>Our Products</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/get-your-q"
                end
                className={({ isActive }) =>
                  isActive
                    ? classes.navigationElementActive
                    : classes.navigationElement
                }
              >
                <div>Get your Q</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact-us"
                end
                className={({ isActive }) =>
                  isActive
                    ? classes.navigationElementActive
                    : classes.navigationElement
                }
              >
                <div>Contact Us</div>
              </NavLink>
            </li>
          </ul>
        </nav>
        <button
          type="button"
          className={classes.mobileToggleContainer}
          onClick={() => {
            setIsNavExpanded(!isNavExpanded);
          }}
        >
          <div className={classes.mobileToggle}>
            <span className={classes.toggleLines} />
            <span className={classes.toggleLines} />
            <span className={classes.toggleLines} />
          </div>
        </button>
      </div>
      {isNavExpanded ? (
        <div
          id="navigation"
          className={
            isNavExpanded
              ? classes.navigationMobileActive
              : classes.navigationMobileHidden
          }
        >
          <ul className={classes.navigationMobile}>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? classes.navigationElementActive
                    : classes.navigationElement
                }
                onClick={() => {
                  setIsNavExpanded(false);
                }}
                to="/"
                end
              >
                <div>Home</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/our-products"
                className={({ isActive }) =>
                  isActive
                    ? classes.navigationElementActive
                    : classes.navigationElement
                }
                onClick={() => {
                  setIsNavExpanded(false);
                }}
              >
                <div>Our Products</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/get-your-q"
                end
                className={({ isActive }) =>
                  isActive
                    ? classes.navigationElementActive
                    : classes.navigationElement
                }
                onClick={() => {
                  setIsNavExpanded(false);
                }}
              >
                <div>Get your Q</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact-us"
                end
                className={({ isActive }) =>
                  isActive
                    ? classes.navigationElementActive
                    : classes.navigationElement
                }
                onClick={() => {
                  setIsNavExpanded(false);
                }}
              >
                <div>Contact Us</div>
              </NavLink>
            </li>
          </ul>
        </div>
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <></>
      )}
    </div>
  );
};

export default NavBar;
