import { AxiosResponse } from 'axios';
import { freshdeskAxios } from '../config/freshdesk-axios.config';

export class FreshdeskService {
  async requestSupport(
    name: string,
    email: string,
    message: string
  ): Promise<AxiosResponse> {
    const url = `${process.env.REACT_APP_FRESHDESK_URL}`;

    const htmlDescription = `
    <div>
      <p>Hello there! ${name} is requesting support!</p>
      <br />
      <p><strong>Email:</strong> ${email}</p>
      <p><strong>Message:</strong> ${message}</p>
    </div>
    `;

    const data = {
      description: htmlDescription,
      subject: 'Support Request',
      email,
      priority: 1,
      status: 2,
      product_id: 69000001102,
    };

    const req: AxiosResponse = await freshdeskAxios.post(url, data);

    return req.data;
  }
}
