import { FC } from 'react';

const DownloadInstructions: FC = () => {
  const userAgent = navigator.userAgent || navigator.vendor;
  if (userAgent.includes('iPhone') || userAgent.includes('Android')) {
    const redirectStore = userAgent.includes('iPhone')
      ? process.env.REACT_APP_QUOTIENT_APP_STORE
      : process.env.REACT_APP_QUOTIENT_PLAY_STORE;
    window.location.replace(redirectStore as string);
    return null;
  }
  return (
    <div>
      <div>This page is a work in progress.</div>
    </div>
  );
};

export default DownloadInstructions;
